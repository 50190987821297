body{
  background:#2A44FF;
  width:100%;
  height:100%;
}
video {
  clip-path: inset(0px 0px);
}

body {
  // background: -moz-linear-gradient(-220deg, #7441A7, #D24940, #788BFF, #5C6FF1, #4D5ADC,#235FFF);
  // background: -webkit-linear-gradient(-220deg, #7441A7, #D24940, #788BFF, #5C6FF1, #4D5ADC,#235FFF);
  // background: linear-gradient(-220deg, #FFBD8F, #D24940, #E37C5C, #5C6FF1, #4D5ADC,#235FFF);
  // background-size: 400% 400%;
  // -webkit-animation: Gradient 5s ease infinite;
  // -moz-animation: Gradient 5s ease infinite;
  // animation: Gradient 5s ease infinite;
  /*min-height: calc(100vh - 2rem);*/
  /* display: flex; */
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative; 
  background-size:400% 800%;
  overflow-y:scroll;
}

body::before, 
body::after {
  // content: "";
  // width: 70vmax;
  // height: 70vmax;
  // position: absolute;
  // background: rgba(255, 255, 255, 0.07);
  // left: -20vmin;
  // top: -20vmin;
  // animation: morph 15s linear infinite alternate, spin 20s linear infinite;
  // z-index: 1;
  // will-change: border-radius, transform;
  // transform-origin: 55% 55%;
  // pointer-events: none; 
}

body::after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.07);
  width: 70vmin;
  height: 70vmin;
  left: auto;
  right: -10vmin;
  top: auto;
  bottom:200px;
  animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
  transform-origin: 20% 20%; 
  animation: morph 15s linear infinite alternate, spin 20s linear infinite;
  z-index: 1;
  will-change: border-radius, transform;
  transform-origin: 55% 55%;
  pointer-events: none; 
  display:none;
}


@keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
  100% {
    border-radius: 40% 60%; 
  } 
}

@keyframes spin {
  to {
    transform: rotate(1turn); 
  } 
}




.headermain{
    z-index:9;
    position:absolute;
    width:100%;
}
// canvas {
//   position: -webkit-sticky;
//   position: -moz-sticky;
//   position: -o-sticky;
//   position: -ms-sticky;
//   position: sticky;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
//   object-fit: cover;
//   object-position: center center;
// }
.zeebuclubbanner{
    position:relative;
    top:0;
    width:100%;
    background:#3f6ee5;
    // height:100vh;
    /* height:100vh;
    min-height:835px; */
    height:100%;
    // background:url(../../images/landingpage/HeroBanner.jpg) no-repeat center;
    // background-size:cover;
    // padding-top:130px;
    // min-height:830px;
    h1{
        text-align:left;
        font-size:54px;
        color:#FFF;
        font-weight:bold;
        line-height:64px;
    }
    p{
        color:#D7D7D7;
        text-align:left;
        font-size:18px;
        padding:10px 30px 10px 0;
    }
    // .socialmediabtn{ text-align:left;
    //     a{
    //         width:auto;
    //         color: #FFFFFF;
    //         padding: 0.875rem 5rem 1rem;
    //         font-size:1rem;
    //         text-decoration: none;
    //         text-align:center;
    //         transition: background-position 0.3s;
    //         display: inline-block;
    //         position: relative;
    //         overflow: hidden;
    //         background:url(../../images/landingpage/btn.jpg) no-repeat center;
    //         background-size:cover;
    //         border-radius:10px;
    //         font-weight:bold;
    //         &:first-child{
    //             margin-bottom:30px;
    //         }
    //         &:hover, &:focus{
    //             background-size:150%;
    //             background-position: right;
    //             background-color: #1b1b1b;
    //             color:#FFFFFF;
    //             text-decoration:none;
    //         }
    //         img{
    //             padding-right:10px;
    //         }
    //     }
    // }
    .socialmediabtn{ text-align:left;
      a{
          width:auto;
          color: #FFFFFF;
          padding: 0.875rem 5rem 1rem;
          font-size:1rem;
          text-decoration: none;
          text-align:center;
          transition: background-position 0.3s;
          display: inline-block;
          position: relative;
          overflow: hidden;
          background:url(../../images/landingpage/btn.jpg) no-repeat center;
          background-size:cover;
          border-radius:10px;
          font-weight:bold;
          &:first-child{
              margin-bottom:30px;
          }
          &:hover, &:focus{
              background-size:150%;
              background-position: right;
              background-color: #1b1b1b;
              color:#FFFFFF;
              text-decoration:none;
          }
          img{
              padding-right:10px;
          }
      }
  }
}

.headerbannerbox{
  // position:absolute;
  // z-index:9;
  .telegrambtnorange{
    // width:40%;
    // float: left;
  }
}


// Video hero banner start

#root{
  background: linear-gradient(0deg, #4877E5 0%, #658be3 13.5%, rgba(72, 119, 229, 0) 100%);
  height:100vh;
}
// .topbannershadow{
//     position:relative;
//     top:-98px;
//   &::after, &::before{
//     content:"";
//     position:relative;
//     width:100%;
//     height:100px;
//     //height:100px;
//     text-align:center;
//     display:inline-block;
//     left: 0;
//     right: 0;
//   }
//   &::before{
//     top:7px;
//     background: linear-gradient(0deg, #4877E5 0%, #4877E5 13.5%, rgba(72, 119, 229, 0) 100%);
//   }
//   &::after{
//     background: linear-gradient(180deg, #4877E5 0%, #4877E5 13.5%, rgba(72, 119, 229, 0) 100%);
//   }
// }



// .videobanner{
//   &::after{
//     content:"";
//     position: relative;
//     width:100%;
//     height:100px;
//     text-align:center;
//     display:inline-block;
//     left:0;
//     right:0;
//     bottom:10px;
//     // box-shadow:0 -26px 30px 30px #4979e8;
//     background: linear-gradient(0deg, #4877E5 0%, #4877E5 13.5%, rgba(72, 119, 229, 0.00) 100%);
//   }
// }
.videobanner {
  // position: relative;
  // padding-bottom: 56.2%;
  // width: 100%;
  // overflow: hidden;
  background:#3f6ee5;
  .videoembed {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    // width:100%;
    // height:100%;
    // position: absolute;
    // top: 0;
    // z-index: -99;
    // width: 100%;
    // height: 100%;
    // background:#2650FE;
  }

  .videobanner-title {
    position: absolute;
    width: 100%;
    top:180px;
    padding: 20px 40px;
    text-align: center;
    box-sizing: border-box;

    h1{
        text-align:left;
        font-size:54px;
        color:#FFF;
        font-weight:bold;
        line-height:64px;
    }
    p{
        color:#D7D7D7;
        text-align:left;
        font-size:18px;
        padding:10px 30px 30px 0;
    }
  }
}

// Video hero banner End


// Sticky Start
  .ends {
    min-height: 50vh;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
  }
  
//   main {
//     display: flex;
//     flex-direction: column;
//   }

.ticketmainbox{
  // min-height:665px;
  min-height:650px;
}  
  .slides {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
    .videobg{
        background:url('../../images/landingpage/secure-videobg.jpg') no-repeat center;
        background-size:contain;
        // height:100vh;
        width:100%;
        text-align:center;
        min-height:500px;
        position: relative;
        top: -70px;
    }
    .timerbox{
        text-align:left;
        /* background:url('../../images/landingpage/Countdownbg.png') no-repeat center;
        background-size:cover; */
        .timerheadtitle{
            font-size:22px;
            color:#000;
            font-weight:700;
        }
    }
    
    // .ticketleftshap{
    //    &::after{
    //     content:"";
    //     background:url('../../images/landingpage/shap-1.png') no-repeat center;
    //     background-size:cover;
    //     position:absolute;
    //     bottom:200px;
    //     width:100%;
    //    }
    // }
    .ticketbox{
        width:100%;
        min-width: 300px;
        min-height: 400px;
        position: relative;
        top:10%;
        a{
            padding: 10px;
            border-radius: 30px;
            width: 100%;
            display: inline-block;
            text-align: center;
            position: absolute;
            bottom: 30px;
        }
    }
    .ticketbox1{
        background:url('../../images/landingpage/Blue.png') no-repeat center;
        a{
            background: red;
        }
    }
    .ticketbox2{
        background:url('../../images/landingpage/Orange.png') no-repeat center;
        a{
            background: blue;
        }
    }
    .ticketbox3{
        background:url('../../images/landingpage/LightBlue.png') no-repeat center;
        a{
            background: pink;
        }
    }
    .stickybgbox{
        // background: #00264B;
        // flex-direction: column;
        // overflow: hidden;
        // text-align: left;
        // width: 95%;
        // padding: 3rem;
        // max-width: 40rem;
        // position: absolute;
        // color: white;
        // text-transform: uppercase;
        // font-size: 4.6rem;
        // line-height: 1.25em;
        // font-weight: 500;
        // left:0;
        // z-index:9999;
      }
    .list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left:0;
      margin-bottom:0;
    }
  
    .slide {
      display: flex;
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;
      
      .backgroundimage {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-position: center;
        will-change: transform;
        background-size: cover;
      }
  
      .content {
        flex-direction: column;
        overflow: hidden;
        display: flex;
        // text-align: left;
        // width: 95%;
        // padding: 3rem;
        // max-width: 40rem;
        position: absolute;
        // color: white;
        // text-transform: uppercase;
        // font-size: 4.6rem;
        // line-height: 1.25em;
        // font-weight: 500;
        left:7.6%;
        top:15%;
      }
    }
  }

//   date timer
.DatetimeCountdown{
    background:url(../../images/landingpage/Countdownbg.png) no-repeat center;
    background-size:cover;
    height:auto;
    width:100%;
    padding:120px 50px;
    margin-top: 50px;
    div{
        width:25%;
        display:inline-block;
        text-align:center;
        font-size:50px;
        color:#FFFFFF;
        line-height:30px;
    }
    span{
        width:100%;
        display:inline-block;
        font-size:16px;
        color:#6B6B6B;
    }
    #days::after, #hours::after, #minutes::after{
        content:"";
        position: relative;
        border-right:1px solid #9B9B9B;
        top:-20px;
    }
}

.introbox{
    .introinner{
      background:linear-gradient(108deg, #FFF1E8 14.72%, rgba(255, 241, 232, 0.00) 145.98%);
      border-radius:20px;
      border:1px solid #FFF;
      text-align:center;
      p{
          margin:0;
          padding:20px;
          color: #3C3C3C;
          font-size: 14px;
      }
      img{
        padding-top:20px;
      }
    }
    .intrologo{
        position: relative;
        margin-bottom:100px;
        z-index:9;
        img{
            position: absolute;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;
            top: -60px;
            width: 220px;
        }
    }
}
// #arrowfixed {
//     left:2rem;
//     position:fixed;
//     top:40px;
//     background:rgba(255,255,255,0.5);
//     height:8rem;
//     width:30rem;
// }

// #arrowfixed {
//     transition: all 0.5s ease 0s;
//     -moz-transition: all 0.5s ease 0s;
//     -webkit-transition: all 0.5s ease 0s;
//     -o-transition: all 0.5s ease 0s;
//     cursor: pointer;
//     z-index:9999;
// }

// Sticky End


// Variables
$background-color: #D4DAFF;
$background-color2: #FCDDD4;
$width: 350;
$circle-size: 50;

img {
	max-width: 100%;
	height: auto;
}

.ticket {
	width: $width + px;
	min-height: 450px;
	background-color: #051A34;
	//margin: 25px auto;
	position: relative;
  border-radius: 20px;
}
.ticket-section{
	width: $width + px;
	min-height: 450px;
	background-color: #F15729;
	// margin: 25px auto;
	position: relative;
    border-radius: 20px;
}
.ticket-three{
	width: $width + px;
	min-height: 450px;
	background-color: #2A44FF;
	margin: 25px auto;
	position: relative;
    border-radius: 20px;
}
.holes-lower {
	position: relative;
	margin:25px;
	border:1px dashed #FFFFFF;
	&:before,
	&:after {
		content: '';
		height: $circle-size + px;
		width: $circle-size + px;
		// background-color: $background-color;
		position: absolute;
		border-radius: 50%;
	}
	
	&:before {
    // background: #E5E7F9;
    // height: $circle-size * 2 + px;
    // width: $circle-size + px;
    // border-bottom-left-radius: $circle-size * 2 + px;
    // border-top-left-radius: $circle-size * 2 + px;
    background: #E5E7F9;
    height: 45px;
    width: 25px;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: -26px;
    top: -25px;
		//top: -25px;
		// left: ($circle-size / -1) + px;
    // left:-42px;
    // background:url(../../images/landingpage//ticketblue_right.svg) no-repeat center;
	}
	&:after {
    background: #E5E7F9;
    height: 45px;
    width: 25px;
    border-bottom-right-radius:0;
    border-top-right-radius:0;
    border-top-left-radius:100px;
    border-bottom-left-radius:100px;
    top: -25px;
    right:-26px;
		// top: -25px;
    // left:290px;
		// left: ($width - $circle-size) + px;
    // background:url(../../images/landingpage//ticketblue_right.svg) no-repeat center;
	}
}
.holes-lower-two {
	position: relative;
	margin:25px;
	border:1px dashed #FFFFFF;
	&:before,
	&:after {
		content: '';
		// height: $circle-size + px;
		// width: $circle-size + px;
		// background-color: $background-color2;
		// position: absolute;
		// border-radius: 50%;
    position: absolute;
	}
	
	&:before {
		// top: -25px;
		// left: ($circle-size / -1) + px;
    background: #FED3BE;
    height: 45px;
    width:25px;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: -26px;
    top: -25px;
	}
	&:after {
		// top: -25px;
		// left: ($width - $circle-size) + px;
    background: #FED3BE;
    height: 45px;
    width: 25px;
    border-bottom-right-radius:0;
    border-top-right-radius:0;
    border-top-left-radius:100px;
    border-bottom-left-radius:100px;
    top: -25px;
    right:-26px;
	}
}
.text-white{
    color:#FFFFFF;
}
.info {
	padding: 15px 25px;
}
.info img{
   width:80px;
}
// .info img{
//     animation: animName 15s linear infinite;
// }
// @keyframes animName {
//     0%{
//        transform: rotate(0deg);
//       }
//    100%{
//        transform: rotate(360deg);
//       }
// }
   
table {
	width: 100%;
	font-size: 18px;
	margin-bottom: 5px;
	tr {
		margin-bottom: 5px;
	}
	td {
		width: 33%;
		font-size: 18px;
	}
}
.bigger {
	font-size: 48px;
    color: #fff;
}
.smaller{
    font-size: 18px;
    display: inline-block;
    margin-left: 12px;
    line-height: 19px;
    color: #fff;
}
.small-size{
    font-size: 14px;
    color:#FFFFFF;
}
.serial {
	padding: 25px;
	table {
		border-collapse: collapse;
		margin: 0 auto;
	}
	td {
		width: 3px;
		height: 50px;
	}
}
.numbers {
	td {
		font-size: 16px;
		text-align: center;
	}
}
.rounded{
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.3);
    margin: 5px;
    line-height: 35px;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    // opacity: 0.3;
    .span-class{
        font-size: 24px;
        color:#FFFFFF;
    }
}
.telegrambtn{
    // width:100%;
    // display:inline-block;
    // text-align:center;
    // margin:0 auto;
    // color:#FFFFFF;
    // text-decoration:none;
    // font-weight:bold;
    // font-size:16px;
    // padding:20px 0;
    // border-radius:15px;
    // margin-top:20px;
    width: 100%;
    display: inline-block;
    border-radius: 20px;
    img{
        padding-right:10px;
    }
}
.blueticketbtn{
     &:hover{
      background:url(../../images/landingpage//Blue-Ticket.gif) no-repeat center;
     }
     background:url(../../images/landingpage//DarkBlueticketbtn.svg) no-repeat center;
}
.orangeticketbtn{
    &:hover{
      background:url(../../images/landingpage//Orange-Ticket.gif) no-repeat center;
    }
    background:url(../../images/landingpage//Orangeticketbtn.svg) no-repeat center;
}
.lightblueticketbtn{
    background:url(../../images/landingpage//Blueticketbtn.svg) no-repeat center;
}




  
//   .headermain {
//     background: linear-gradient(180deg, #F15729 1.36%, rgba(241, 87, 41, 0.00) 100%);
//     width:50%;
//     height:20vh;
//     background-size: cover;
//     background-blend-mode: hard-light;
//     -webkit-animation: hue-rotate 3s linear infinite;
//             animation: hue-rotate 3s linear infinite;
//   }
  
//   @-webkit-keyframes hue-rotate {
//     from {
//       -webkit-filter: hue-rotate(0);
//       -moz-filter: hue-rotate(0);
//       -ms-filter: hue-rotate(0);
//       filter: hue-rotate(0);
//     }
//     to {
//       -webkit-filter: hue-rotate(360deg);
//       -moz-filter: hue-rotate(360deg);
//       -ms-filter: hue-rotate(360deg);
//       filter: hue-rotate(360deg);
//     }
//   }
  
//   @keyframes hue-rotate {
//     from {
//       -webkit-filter: hue-rotate(0);
//       -moz-filter: hue-rotate(0);
//       -ms-filter: hue-rotate(0);
//       filter: hue-rotate(0);
//     }
//     to {
//       -webkit-filter: hue-rotate(360deg);
//       -moz-filter: hue-rotate(360deg);
//       -ms-filter: hue-rotate(360deg);
//       filter: hue-rotate(360deg);
//     }
//   }



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
 
  .video-container {
    position: relative;
    // background-color:#536FFF;
    background:rgba(255, 255, 255,0.5);
    padding:7px 7px 0 7px; 
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    z-index: 1001;
    video{
      width:100%;
    }
  }
 
  .open-button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    background:transparent;
    border:none;
    position: relative;
    top:100px;
  }
 
 
  .close-button {
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    background-color:transparent;
    opacity: 0.8;
    top:-40px;
    right:-40px;
    padding: 5px;
    font-size: 24px;
    transition: 0.3s ease-out;
    z-index:9;
    border:none;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 80%;
      height:4px;
      background-color: #FFF;
      left: 50%;
      top: 50%;
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:hover,
    &:focus {
      transform: scale(1.15);
      opacity: 1;
    }
  }
  /* Desktop view */
@media (min-width: 768px) {
    .video-container {
      width:60%; /* Adjust as needed */
    }
  }
  /* Tablet view */
  @media (max-width: 767px) {
    .video-container {
      width:70%; /* Adjust as needed */
    }
  }
  /* Mobile view */
  @media (max-width: 480px) {
    .video-container {
      width: 90%; /* Adjust as needed */
    }
    .close-button {
      font-size: 20px;
    }
  }





  // New page start
 
// .bluebackground{
//   background:url(../../images/landingpage/blue-ticketbg.jpg) no-repeat center;
// }
// .orangebackground{
//   background:url(../../images/landingpage/orange-ticketbg.jpg) no-repeat center;
// }

.ticketshap{
    content:"";
    position:absolute;
    height:15px;
    width:100%;
    z-index: 9;
    left: -490px;
}
.blueshap{
  bottom:0;
  background:url(../../images/landingpage//BlueShap.png) no-repeat center;
}
.orangeshap{
  top:0;
  background:url(../../images/landingpage//OrangeShap.png) no-repeat center;
}
  .slidesmain {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
  
    ul{
      padding:0;
      margin:0; 
    }
  
  .slidesection {
      display: flex;
      width: 100vw;
      height: 100vh;
      position: relative;
      overflow: hidden;
  
      .commanbackground {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-position: center;
        will-change: transform;
        background-size: cover;
        padding-top:50px;
      }
  
      .ticketcontent {
        flex-direction: column;
        justify-content: left;
        overflow: hidden;
        display: flex;
        text-align: center;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
        color: white;
        width:100%;
        .textbg{
          background:url('../../images/landingpage/textcity.png') no-repeat center;
          background-size:cover;
          // height:100vh;
          width:100%;
          text-align:center;
          //min-height:450px;
          position: relative;
          top:50px;
          &::after{
            content:"";
            position: relative;
            background:radial-gradient(46.71% 46.71% at 50% 50%, rgba(42, 68, 255, 0.00) 20%, #2A44FF 100%);
            display:inline-block;
            min-height:600px;
            width:100%;
          }
          .textanimation{
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            bottom:37%;
          }
        }
        .videobg{
          background:url('../../images/landingpage/secure-videobg.jpg') no-repeat center;
          background-size:contain;
          // height:100vh;
          width:100%;
          text-align:center;
          min-height:500px;
          position: relative;
          top:40px;
        }
        .ticketblue{
          background:#192E48;
        }
        .ticketorange{
          background:#F15729;
        }
        .commanticket{
          width:100%;
          aspect-ratio:1.5;
          display: inline-block;
          -webkit-mask: radial-gradient(25px at 25px 98px,#0000 98%,#000) -24px 120px;
          transition: .3s;
          cursor: pointer;
          border-radius:20px;
          padding:20px 22px;
          //margin-top:10px;
          z-index:9;
          margin:50% 0;
        }
        .tickethead{
          padding-bottom:16px;
          table {
            width: 100%;
            font-size: 18px;
            margin-bottom: 5px;
            tr {
              margin-bottom: 5px;
            }
            td {
              width: 33%;
              font-size: 18px;
              img{
                width:80px;
                float: left;
              }
              .ticketdate{
                h2{
                  font-size:48px;
                  color: #fff;
                  padding-top:15px;
                  span{
                    font-size:18px;
                    display: inline-block;
                    margin-left:12px;
                    line-height:19px;
                  }
                }
                p{
                  padding:0;
                  margin:0;
                  font-size:14px;
                }
              }
              
            }
          }
          .ticketdetails{
            font-size: 14px;
            text-align: left;
            padding: 10px 0;
          }
        }
        .membercounter{
          text-align:left;
          padding-top:40px;
          border-top: 1px dashed;
          .membertitle{
            font-size:18px;
          }
          span{
            border-radius: 10px;
            border: 1px solid #c4c4c4;
            padding:3px 10px;
            margin-right: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
            display: inline-block;
          }
        }

        .benifitbox{
          padding-top:150px;
          background:url(../../images/landingpage/BenifitZeebuBg.svg) no-repeat top center;
          background-size:cover;
          min-height:650px;
          .benifitinner{
            // background:linear-gradient(108deg, #FFF1E8 14.72%, rgba(255, 241, 232, 0.00) 145.98%);
            background:rgba(255, 255, 255, 0.2);
            border-radius:20px;
            border:1px solid #FFF;
            text-align:center;
            p{
                margin:0;
                padding:0 20px 20px;
                color: #FFFFFF;
                font-size: 14px;
            }
            img{
              padding-top:20px;
            }
          }
          .benifitlogo{
              position: relative;
              margin-bottom:100px;
              z-index:9;
              img{
                  position: absolute;
                  margin: 0 auto;
                  text-align: center;
                  left: 0;
                  right: 0;
                  top: -60px;
                  width: 220px;
              }
          }
      }
    }
  }
}

// Countdown Start
.orangeticket{
  #countdown{
     div{
        span{
          background:#F15729 !important;
          border: 1px solid #F15729 !important;
        }
     }
  }
}
.ticketcountdown{
  // margin-top:60px;
  // min-height:88px;
  #countdown{
    text-align:center;
    div {
      display: inline-block;
      font-size:0.8em;
      list-style-type: none;
      font-weight: bold;
      text-transform:none;
      color:#FFFFFF;
      span {
        display: block;
        font-size:1.3rem;
        background:transparent;
        border: 1px solid #192E48;
        background:#192E48;
        box-shadow:none;
        // box-shadow: 0px 4px 19px 0px rgba(255, 255, 255, 0.20) inset, 0px 4px 14px 0px rgba(0, 0, 0, 0.20);
        border-radius:10px;
        margin: 5px 5px 5px 5px;
        padding:12px 0;
        color:#FFFFFF;
        min-width:60px;
        font-weight:bold;
      }
    }
  
    background:none;
    width:100%;
    padding:0px;
  }
}
#countdown{
  text-align:center;
  // background:url('../../images/landingpage/Countdownbg.png') no-repeat top center;
  // background-size:cover;
  margin-top:-50px;
  padding:0px 0 0;
  div {
    display: inline-block;
    font-size: 1em;
    list-style-type: none;
    font-weight: bold;
    text-transform: uppercase;
    color:#FFFFFF;
    span {
      display: block;
      font-size: 4.5rem;
      background:linear-gradient(180deg, rgba(59, 109, 236, 0.22) 0%, rgba(65, 117, 239, 0.46) 100%);
      border: 1px solid rgba(255, 255, 255, 0.40);
      box-shadow: 0px 4px 19px 0px rgba(255, 255, 255, 0.20) inset, 0px 4px 14px 0px rgba(0, 0, 0, 0.20);
      border-radius:20px;
      min-width:150px;
      margin: 5px 5px 15px 5px;
      padding: 20px 0;
      color:#FFFFFF;
    }
  }

  // background:url(../../images/landingpage//bluesectionbg.svg) no-repeat center;
  // width: 100%;
  // background-size: cover;
}




@media all and (max-width: 767px) {

  #countdown div {
    font-size: calc(1.125rem * .75);
    font-weight:normal;
  }
  #countdown div span {
    font-size: calc(2.375rem * .75);
    min-width:80px;
    font-weight:bold;
  }
  .footermain{
    height: 100%;
  }
  .slides {
    .videobg{
      min-height:300px;
      top:0;
    }
  }
  .zeebuclubbanner{
    min-height: 500px;
    height:100vh;
    .videobanner{
      .videobanner-title{
        // top:220px;
        top:30%;
        padding:20px;
        h1{
          font-size: 34px;
          line-height: 44px;
        }
      }
    }
  }
}
  
// Countdown End


// Text flip Start
.textanimation{
  .text {
    font-weight:bold;
    font-size:100px;
    // color: #F15E2A;
    color:#B5C8FB;
  }
  .dud {
    color:rgba(255, 255, 255, 0.2);
  }
}
// Text flip end



// Telegram Button Hover Start
.cursor, .cursor1, .cursor2 {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-25%) translateY(-25%);
  pointer-events: none;
  left:0px;
  top:50%;

  &.cursor1, &.cursor2 {
      height:30px;
      width:30px;
      z-index: 99998;
      transition: all 0.2s ease-out;

      &.hover {
        -webkit-transform:scale(2) translateX(100%) translateY(100%);
        transform:scale(2) translateX(100%) translateY(100%);
        border:none;
        background: rgba(255,255,255,.2);
        box-shadow: 0 0 14px rgba(255, 255, 255, 0.6);
      }
  }

  @media screen and (max-width: 1200px) {
      display: none;
  }
}

.buttonlink {
  position: relative;
  text-align: center;
  font-size:16px;
  font-weight:500;
  // top: 50%;
  // left: 50%;
  color: #fff;
  z-index: 2;
  // transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease-out;
  padding:15px 0;
  margin-top:10px;
  border-radius:20px;
  cursor: pointer;
  text-decoration: none;
}


// Telegram Button Hover End




@media screen and (max-width: 767px) {
  body::after{
    display:none;
  }
  .mobileresponsive{
    > div:first-child #countdown{
      display: none;
    }
    .ticketcountdown{
      width: 100%;
      #countnumberid{
        display:block !important;
      }
    }
  }
  .mobileresponsive{
    .ticketcountdown{
      padding:0;
      margin-top: 0;
      position:fixed;
      bottom:0px;
      z-index:999;
      #countdown{
        div{
          background:#192E48 !important;
          color: #FFF;
          font-size:0.8rem;
          background: transparent;
          border:none;
          box-shadow:none;
          border-radius:10px 10px 0 0;
          margin: 5px 5px -1px 5px;
          padding:5px 0 2px;
          font-weight: normal;
          width:18%;
          span{
            background:transparent;
            color: #FFF;
            box-shadow:none;
            display: block;
            font-size:1.5rem;
            border: none;
            box-shadow: none;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .buttonlink{
      top: 0;
      transform: translateX(-50%) translateY(-10%);
      margin-top: 0;
    }
    .mobileticket{
      border-radius:20px 20px 0 0;
      padding:10px 0px 10px;
      background:#192E48;
      margin:0 10px;
      .mobiletitle{
        color:#FFF;
        img{
          width:40px;
          float:left;
          margin-top:5px;
        }
        p{
          font-size:10px;
          font-weight:500;
          margin:0 0 0 5px;
          display:inline-block;
        }
        span{
          font-size:20px;
          font-weight:600;
          margin:0 0 0 5px;
        }
      }
      .enddate{
        color:#FFFFFF;
        font-size:10px;
        text-align:center;
        padding-top:5px;
      }
    }
    .textbg{
      background:url('../../images/landingpage/textcity.png') no-repeat center;
      background-size:cover;
      // height:100vh;
      width:100%;
      text-align:center;
      //min-height:500px;
      height:100vh;
      position: relative;
      top:0px;
      &::after{
        content:"";
        position: relative;
        background:radial-gradient(46.71% 46.71% at 50% 50%, rgba(42, 68, 255, 0.00) 20%, #2A44FF 100%);
        display:inline-block;
        //min-height:480px;
        min-height:100vh;
        width:100%;
      }
      .textanimation{
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom:45%;
        .text{
          font-size:42px;
        }
      }
    }
    .orangebackground {
      position:relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      padding-top:50px;
      .benifitbox{
          padding-top:20px;
          padding-bottom:0px;
          background:url(../../images/landingpage/BenifitZeebuBg.svg) no-repeat center;
          background-size:contain;
          .benifitinner{
            background:rgba(255, 255, 255, 0.2);
            border-radius:20px;
            border:1px solid #FFF;
            text-align:center;
            margin-bottom:15px;
            p{
                margin:0;
                padding:0 20px 20px;
                color: #FFF;
                font-size: 14px;
            }
            img{
              padding-top:20px;
            }
          }
      }
    }
  }
  .footermain {
    height: 100%;
    padding: 40px 20px 120px;
    .footerinner{
      padding: 30px 20px;
    }
    .disclaimerdetails{
      padding:40px 0;
    }
  }
}


.slidesmain {
    ul{
      li{
        flex-direction: column;
        align-items: stretch;
        justify-content: space-evenly;
        overflow: hidden;
        position: relative; 
        background-size:400% 800%;
        overflow-y:scroll;
        &:first-child, &:last-child{
            &::after{
              content: "";
              position: absolute;
              width:80vmin;
              height:50vmin;
              border-radius:250px;
              animation: morph1 10s linear infinite alternate, spin 26s linear infinite reverse;
              transform-origin: 20% 20%; 
              animation: morph1 15s linear infinite alternate, spin 20s linear infinite;
              z-index: 1;
              // will-change: border-radius, transform;
              transform-origin: 55% 55%;
              pointer-events: none; 
              bottom:150px;
              top: auto;
              opacity:0.5;
            }
        }
        &:first-child{
          &::after{
            background:radial-gradient(112.68% 112.61% at 42.64% -5.82%, #F6A23A 33.65%, #F15729 44.58%, #FF7547 100%);
            filter: blur(110px);
            left: auto;
            right: -10vmin;
          }
        }
        &:last-child{
          &::after{
            background:#192E48;
            filter: blur(110px);
            left:-10vmin;
            right:auto;
            z-index: -1;
          }
        }
      }
    }
}


@keyframes morph1 {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
  100% {
    border-radius: 40% 60%; 
  } 
}








#marbles {
    width: 100%;
    height:60px;
    border-radius: 20px;
    text-align: center;
    font-size: 20px;
    // border: 1px solid rgba(255,255,255, 0.5);
    margin-top:20px;
    //background:linear-gradient(170deg, #4B607A 3.98%, #243953 96.12%, #2D425C 96.12%);
    background:#2D425C;
    a{
      text-decoration: none;
      color: #FFFFFF;
      text-align: center;
      display: inline-block;
      width:100%;
      padding:15px 0;
      border-radius: 20px;
    }
    canvas{
      height:56px !important;
      position: relative;
      top: -60px;
    }
}



.telegrambtnv1{
  width: 100%;
  display: inline-block;
  border-radius: 20px;
  background:url(../../images/landingpage//DarkBlueticketbtn.svg) no-repeat center;
}

.buttonmain{
    width: 100%;
    position: relative;
    text-align: center;
    height:60px;
    color: #FFF;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  .telbtn{
    // width: 100%;
    // text-align: center;
    // display: inline-block;
    // font-size:18px;
    // padding-top:15px;
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size:16px;
    padding:18px 0;
    img{
      padding-right:8px;
    }
  }
}




$buttonBackground: #2c3e50;
$buttonColor: rgba(255,255,255,1);

.sparkley {

  background: lighten( $buttonBackground , 10% );
  color: $buttonColor;
  border: none;

  padding: 16px 36px;
  font-weight: normal;
  
  border-radius: 3px;
  transition: all 0.25s ease;
  
  box-shadow: 0 38px 32px -23px black;
  margin: 0 1em 1em;
  
  &:hover {
    background: $buttonBackground;
    color: transparentize( $buttonColor,0.8 );
  }
}


.telegrambtnv2{
  &:hover{
    background:url(../../images/landingpage//Blue-Output-New.gif) no-repeat center;
   }
  width: 100%;
  display: inline-block;
  border-radius: 20px;
  background:url(../../images/landingpage//DarkBlueticketbtn.png) no-repeat center;
  height:60px;
  background-size:cover;
}
.telegrambtnorange{
  &:hover{
    background:url(../../images/landingpage/Orange-Ticket.gif) no-repeat center;
   }
  width: 100%;
  display: inline-block;
  border-radius: 20px;
  background:url(../../images/landingpage/Orange-button-new-Output.png) no-repeat center;
  height:60px;
  background-size:cover;
}








body,
html {
  margin: 0;
}
.scroller {
  height: 100vh;
}

.orange {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #753500;
}

.text {
  color: #fff;
}

.black {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.blue {
  height: 100vh;
  background-color: #00026d;
}


.text-wrap {
  position: relative;
  overflow: hidden;
  width: 450px;
  height: 80vh;
}

.panel-text {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 900;
  text-align:center;
  transform:translateY(100%);
  opacity: 0;
}

.panel-text.blue-text {
  color: blue;
}

.panel-text.red-text {
  color: red;
}

.panel-text.purple-text {
  color: purple;
}

.panel-text.orange-text {
  color: orange;
}






.p-wrap {
  position: relative;
  overflow: hidden;
  width: 450px;
  height: 80vh;
}

.panel {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  // z-index: 1;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

// .panel._2 {
//   z-index: 1;
 
// }

// .panel.blue {
//   z-index: 1;
// }


.panel.purple {
  z-index: 9;
  background-color: #808;
  background-image: none;
}
.gsap-marker-scroller-end, .gsap-marker-scroller-start, .gsap-marker-start, .gsap-marker-end{
  display:none !important;
}