@media all and (max-width: 767px) {
    .moreless{
        color: #F1572A;
        text-shadow: none;
        font-size: 18px;
        font-weight: normal;
    }
    .mobile-herobanner{
        padding-top:100px;
        // background:url('../images/landingpage/mobile_herobg.jpg') no-repeat center;
        // background-size:cover;
        //background: linear-gradient(130deg, #AE4E57 8%, #073dbe 35%), #FFF;
        background: linear-gradient(130deg, #AE4E57 8%, #1c39c2 35%), #FFF;
        .telegrambtnorange{
            // width:50%;
            // margin-left:15px;
            // .telbtn{
            //     padding-top:15px !important;
            // }
        }
        h1{
            font-weight: 700;
            font-size:34px;
            color: #FFFFFF;
            line-height: 50px;
            padding:0 15px;
        }
        p{
            font-size:16px;
            color:#FFF;
            padding:0 15px 20px;
        }
        .mobileherovideo{
            //box-shadow:inset 0 4px 8px 0 rgba(9, 55, 199, 0.2), 0 6px 20px 0 rgba(9, 55, 199, 0.19);
            position:relative;
            top:20px;
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height:100%;
                //height:52px;
                text-align: center;
                display: inline-block;
                left: 0;
                right: 0;
                //background: linear-gradient(180deg, #4877E5 0%, #4877E5 13.5%, rgba(72, 119, 229, 0) 100%);
                background:linear-gradient(180deg, #4b6de0 0%, #4b6de1 13.5%, rgba(72, 119, 229, 0) 100%);
                z-index: 9;
                //bottom: -50px;
                bottom:-90%;
            }
            video{
                width:100%;
                height:100%;
                // bottom: -6px;
                // position: relative;
            } 
        }
    }
    .mobilevideomain{
        height:70vh;
        .mobilevideobg{
            background:url('../images/landingpage/secure-videobg.jpg') no-repeat center;
            text-align:center;
            background-size:cover;
            min-height:60vh;
            .open-button{
                top:240px;
            }
            .close-button{
                right:-15px;
            }
        }
    }
    .benifitmainbox{
        background:url('../images/landingpage/BenifitZeebuBg.svg') no-repeat center;
        background-size:contain;
        height:80vh;
        position:relative;
        overflow:auto;
        overflow-x: hidden;
        .benifitbox{
            margin:50vh 0;
            .benifitinner{
                background:rgba(255, 255, 255, 0.2);
                border-radius:20px;
                border:1px solid #FFF;
                text-align:center;
                p{
                    margin:0;
                    padding:20px;
                    color: #FFFFFF;
                    font-size: 14px;
                }
                img{
                    padding-top:20px;
                }
            }
        }
    }
    .telegrambtnv2{
        //height: 40px !important;
    }
    .buttonmain{
        //height:auto;
        .telbtn{
            //padding-top:4px !important;
            font-size:14px !important;
        }
    }

    body, html {
        margin: 0;
        padding: 0;
        scroll-snap-type: y mandatory; /* Enable vertical scroll snapping */
        overflow-y: scroll; /* Ensure scrolling is enabled */
        height: 100vh; /* Limit the viewport height to enforce snapping */
      }
    .full-screen-section {
        height: 100vh; /* Each section will take up the full viewport height */
        scroll-snap-align: start; /* Snap alignment to the start of the section */
    }
    body::after{
        display:none !important;
    }
}



/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    #countdown{margin-top:60px !important;}
    .videobanner{
        .videobanner-title{
            h1{
                font-size:42px !important;
                line-height:52px !important;
            }
        }
    }

    // .ticketshap{
    //     left: -320px !important;
    // }
    .slidesmain{
        ul{
            li{
                &:first-child, &:last-child{
                    &::after{
                        width: 50vmin !important;
                        height: 30vmin !important; 
                        bottom:300px;
                    }
                }
            }
        }
    }
    body::after{
        display:none !important;
    }
    .buttonmain{
        .telbtn{
            font-size:14px !important;
        }
    }
}
/* Portrait */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .ticketshap{
        left: -320px !important;
    }
    body::after{
        display:none !important;
    }
}
/* Landscape */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .tabletview{
        .videobanner-title {
            top: 190px !important;
        }
    }
}

/* ----------- iPad mini, Air ----------- */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .footermain{
        //height:80vh;
    }
    .moreless{
        color: #F1572A;
        text-shadow: none;
        font-size: 18px;
        font-weight: normal;
    }
    .orangeshap, .blueshap{
        display:none;
    }
    .tabletview{
        .videobanner-title{
            top:60px !important;
            .headerbannerbox{
                .headtitlebox{
                    h1{
                        font-size:24px;
                        line-height:36px;
                    }
                }
            }
        }
        .ticketcountdown{
            #countdown{
                div{
                    font-size:0.6rem;
                    span{
                        min-width:48px;
                    }
                }
            }
        }
        .ticketshap{
            left: -215px;
        }
        .textanimation{
            .text{
                font-size:50px;
            }
        }
        .slidesmain{
            ul{
                li{
                    &:first-child, &:last-child{
                        &::after{
                            width:50vmin;
                            height:40vmin;
                        }
                    }
                }
            }
            
            .slidesection{
                height:80vh;
                .ticketcontent {
                    .membercounter{
                        span{
                            font-size:16px;
                            padding:5px 10px;
                        }
                    }
                }
            }
        }
    }
}