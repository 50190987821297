@import 'variable';
// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');

* {
    outline: none;
}

body {
    background: $bodycolor;
    font-family: $bodyfont;
    margin: 0;
    overflow-x: hidden;
    color: $bodytext;
    font-weight: 300;
}

html {
    position: relative;
    min-height: 100%;
    background: $white;
}

a:hover,
a:focus {
    text-decoration: none;
}

a.link {
    color: $headingtext;

    &:hover,
    &:focus {
        color: $themecolor;
    }
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $headingtext;
    font-family: $headingfont;
}

h1 {
    line-height: 40px;
    font-size: 36px
}

h2 {
    line-height: 36px;
    font-size: 30px
}

h3 {
    line-height: 30px;
    font-size: 24px
}

h4 {
    line-height: 26px;
    font-size: 21px
}

h5 {
    line-height: 22px;
    font-size: 18px;
    font-weight: 400;
}

h6 {
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
}
