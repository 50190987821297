.footermain{
    height:100%;
    // height:100vh;
    width:100%;
    width:100vw;
    padding:80px 0 80px;
    // background-image: linear-gradient(to right bottom, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    // background:linear-gradient(to right bottom, #2A44FF 0%, #2A44FF 35%, #994d85 100%);
    // background-image: linear-gradient(to right bottom, #924c8d, #7c4aa4, #774aab, #2A44FF, #2A44FF, #2A44FF, #2A44FF, #2A44FF, #774aab, #7c4aa4, #924c8d);
    background:url(../images/landingpage/footerbg.jpg) no-repeat center;
    background-size: cover;
    // background-blend-mode: hard-light;
    -webkit-animation: hue-rotate 5s linear infinite;
            animation: hue-rotate 5s linear infinite;
    .footerlink{
        text-align:right;
        a{
            text-decoration:none;
            font-size:16px;
            color:#FFF;
            &:first-child{
                padding-right:25px;
            }
        }
    }
    .disclaimerdetails{
        padding:50px 0;
        span{
            font-weight:bold;
            color:#FFFFFF;
            font-size: 20px;
            padding-bottom: 20px;
            display: inline-block;
        }
        p{
            color:#FFFFFF;
            font-size:14px;
        }
    }
    .copyright{
        border-top:1px solid rgba(255, 255, 255, 0.5);
        padding:30px 0 0;
        font-size:14px;
        color:#D7D7D7;
        text-align:center;
        width:100%;
    }
    .footerinner{
        padding:30px 50px;
        border-radius: 20px;
        border: 1px solid #FFF;
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 0px 0px 38.231px 0px rgba(255, 255, 255, 0.08) inset;
        backdrop-filter: blur(7.5px);
    }

        
    // @keyframes hue-rotate {
    //     from {
    //         -webkit-filter: hue-rotate(0);
    //         -moz-filter: hue-rotate(0);
    //         -ms-filter: hue-rotate(0);
    //         filter: hue-rotate(0);
    //     }
    //     to {
    //         -webkit-filter: hue-rotate(360deg);
    //         -moz-filter: hue-rotate(360deg);
    //         -ms-filter: hue-rotate(360deg);
    //         filter: hue-rotate(360deg);
    //     }
    // }       
}